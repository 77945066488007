<template>
	<b-card class="a-card-menu" v-if="cnp">
		<h3 class="w-color-black fw-bold mb-4">{{ title }}</h3>
		<b-list-group flush>
			<b-list-group-item
				v-for="(cnpItem, i) of cnp" :key="i"
				:to="cnpLink(cnpItem)"
				@click.prevent="onClick(cnpItem)"
				:class="{ 'completed': cnpItem.is_completed }"
			>
				<div class="d-flex align-items-center pl-2">
					<div class="image" :class="{ 'completed': showCompleted && cnpItem.is_completed}">
						<img :src="`${cnpItem.image && cnpItem.image.path}`" class="img-fluid" />
						<svg-checked-circle-icon v-if="showCompleted && cnpItem.is_completed" class="image__completed-icon" />
					</div>
					<span>
						<svg-series-icon v-if="cnpItem._model == 'path'" class="icon mr-1" />
						<svg-open-lock-icon v-if="!isLoggedIn && cnpItem.is_public == '1'" class="is-public-icon w-color-dark-green" />
						<!-- <svg-closed-lock-icon v-if="!isLoggedIn && coursePlayerMode == 'path' && cnpItem.is_public == '0'" class="is-public-icon w-color-dark-red" /> -->
						{{ cnpItem.name }}
					</span>
				</div>
			</b-list-group-item>
		</b-list-group>
	</b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	components: {
		'svg-series-icon': 			() => import('@/plugins/lib@course/path/icon/icon-series.svg?inline'),
		'svg-checked-circle-icon':	() => import('@/plugins/appzmudri/_theme/icon/checked-circle.svg?inline'),
		'svg-open-lock-icon': 		() => import('@/plugins/appzmudri@component/course-card/assets/open-lock.svg?inline'),
		// 'svg-closed-lock-icon': 		() => import('@/plugins/appzmudri@component/course-card/assets/closed-lock.svg?inline')
	},
	props: {
		title: { required: true, type: String },
		showCompleted: { type: Boolean, default: false },
		/** Combination of Courses and Paths */
		cnp: { required: true, type: Array },
		coursePlayerMode: { default: 'course', type: String },
	},
	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn'
		])
	},
	methods: {
		onClick(cnpItem) {
			const cnpUrl = this.cnpLink(cnpItem)
			if (!this.isLoggedIn && cnpItem.is_public == 0) {
				localStorage.setItem('LAST_FROM_ROUTE', cnpUrl)
				this.EventBus.$emit('locked-content-accessed')
				return
			}

			this.$router.push(cnpUrl)
		},
		/** Generate a link based on an underlying model: Course or Path */
		cnpLink(cnpItem) {
			if (cnpItem._model == 'path') {
				return `/seria/${cnpItem.slug || cnpItem.id}`
			} else {
				return `/kurz/${cnpItem.slug || cnpItem.id}`
			}
		},
	}
}
</script>

<style lang="scss" scoped>
h3 {
	border-bottom: 0;
	padding-bottom: 0;
}

.icon::v-deep path {
	fill: #EB1B69;
}

.list-group-item {
	font-weight: bold;

	&.completed {
		background-color: rgba(144,238,144, 0.2);
	}

	&.router-link-exact-active {
		background-color: var(--a-color-primary);
		color: var(--a-color-white);
	}

	.image {
		flex: 0 0 4.5rem;
		margin-right: 1.5rem;
		position: relative;

		&.completed {
			&::before {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: lightgreen;
				opacity: 0.9;
				z-index: 1;
			}
		}

		.image__completed-icon {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0; 
			right: 0; 
			margin: auto;
			width: 25px;
			color: var(--a-color-black);
			z-index: 2;
		}
	}
}

.is-public-icon {
	width: 1.5rem;
	margin-right: 0.25rem;
}
</style>
